import React, { useEffect, useState } from "react";
import { Typography, Paper, Collapse, Grid, Button } from "@material-ui/core";
import MyFileUpload from "../../fields/MyFileUpload";
import { StaticImage } from "gatsby-plugin-image";
import { Formik, Form } from "formik";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Box } from "@material-ui/core";
import { addQuote } from "../../../api";
import { navigate } from "gatsby";
import addVehicleScheme from "../../../helper/schemes/addVehicleScheme";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Loading from "../../Loading";
import useStyles from "../../../styles/addVehicle";

const Vehicle = ({ setMessage }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isSucceed, setIsSucceed] = useState(null);
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView();
  }, []);

  const submitData = (data, formikProps) => {
    const unauthorizedCallback = () => {
      if (typeof window !== "undefined") {
        navigate("/login");
      }
    };
    setLoading(true);
    addQuote(data, unauthorizedCallback).then((success) => {
      if (success) {
        formikProps.resetForm();
        setOpen(false);
        setIsSucceed(success);
      }
      setMessage();
      setLoading(false);
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setIsSucceed(null);
    }, 5000);
  }, [isSucceed]);
  if (loading) return <Loading />;
  return (
    <Formik
      initialValues={{
        frontUpload: "",
      }}
      validationSchema={addVehicleScheme}
      onSubmit={(values, formikProps) => {
        submitData(values, formikProps);
      }}
    >
      {() => (
        <Form className={classes.login}>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12} align="start">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClick}
                  className={classes.btn}
                >
                  <AddCircleIcon className={classes.icon} />
                  <Typography variant="body1">Fahrzeug hinzufügen</Typography>
                </Button>
              </Grid>
            </Grid>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className={classes.collapse}
            >
              <Paper elevation={0} className={classes.paper}>
                <Grid container className={classes.container}>
                  <Grid item xs={12} md={8} lg={8}>
                    <div className="mt-12 w-full lg:w-[957px]"></div>
                    <Typography
                      component="h1"
                      variant="h6"
                      className={classes.text}
                    >
                      Zulassungsbescheinigung Teil 1
                    </Typography>
                    <Typography
                      component="h2"
                      variant="body1"
                      color="secondary"
                    >
                      (Vorderseite)
                    </Typography>
                    <div className={classes.upload}>
                      <Typography variant="body2">
                        Wählen Sie per Klick eine Datei auf das Feld oder legen
                        Sie sie hier ab (JPG, PNG, PDF)
                      </Typography>
                      <Box my={4} className={classes.uploadBox}>
                        <MyFileUpload
                          label="Vorderseite"
                          id="frontUpload"
                          name="frontUpload"
                        />
                        <StaticImage
                          src="../../../media/images/Zulassungsbescheinigung1.jpg"
                          className={classes.image1}
                          alt="Zulassungsbescheinigung1"
                        />
                      </Box>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={5}
                    className={classes.zulassung}
                  ></Grid>
                  <Grid item xs={12} className={classes.gridBtn}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.saveBtn}
                    >
                      Speichern
                    </Button>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>{" "}
              </Paper>
            </Collapse>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default Vehicle;
