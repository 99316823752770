import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Container,
  Grid,
  IconButton,
  Divider,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BackupIcon from "@material-ui/icons/Backup";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import EuroIcon from "@material-ui/icons/Euro";
import ExtensionIcon from "@material-ui/icons/Extension";
import { lighten } from "@material-ui/core/styles";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.light,
    minHeight: "100vh",
    color: theme.palette.primary.dark,
    paddingBottom: "2rem",
    marginTop: "4rem",
  },
  title: {
    paddingTop: "2rem",
    fontSize: "clamp(2rem, 6vw, 4rem)",
  },
  gridSubtitle: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
  },
  stepGrid: {
    display: "flex",
    alignItems: "center",
  },
  cardGridKunde: {
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    borderRadius: "5px",
    background: "transparent",
    padding: "1rem",

    backdropFilter: "blur(5px)",
    transition: "1s",
  },
  cardGridFirma: {
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    borderRadius: "5px",
    background: lighten(theme.palette.primary.main, 0.9),
    padding: "1rem",

    backdropFilter: "blur(5px)",
    transition: "1s",
  },
  step2: {
    marginBottom: "2rem",
  },
  stepsContainer: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  divider: {
    borderTop: "1px solid #fff",
    marginBottom: "1rem",
  },
  number: {
    fontSize: "clamp(1rem, 6vw, 4rem)",
  },
  content: {
    marginTop: "0.5rem",
    fontSize: "clamp(0.9rem, 3vw, 1.2rem)",
  },
}));

const Ablauf = () => {
  const year = new Date().getFullYear();
  const classes = useStyles();
  const prices = useSelector((state) => state.prices.items);
  const [m1Price, setM1Price] = useState(false);
  useEffect(() => {
    const newM1Price = (prices || []).find((p) => p.type === "m1");
    if (newM1Price?.price) setM1Price(newM1Price.price);
  }, [prices, setM1Price]);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid item xs={12}>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            className={classes.title}
          >
            So funktioniert&apos;s
          </Typography>
        </Grid>

        <Grid container className={classes.stepsContainer}>
          <Grid item xs={1} className={classes.stepGrid}>
            <Typography variant="h3" className={classes.number}>
              1.
            </Typography>
          </Grid>
          <Grid item xs={11} className={classes.cardGridKunde}>
            <Grid item xs={12} className={classes.gridSubtitle}>
              <IconButton>
                <AccountCircleIcon className={classes.icon} />
              </IconButton>

              <Typography variant="h6">Registrieren</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.content}>
                Registrieren Sie sich ganz einfach auf unserer Seite.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.step2}>
          <Grid item xs={1} className={classes.stepGrid}>
            <Typography variant="h3" className={classes.number}>
              2.
            </Typography>
          </Grid>
          <Grid item xs={11} className={classes.cardGridKunde}>
            <Grid item xs={12} className={classes.gridSubtitle}>
              <IconButton>
                <BackupIcon className={classes.icon} />
              </IconButton>

              <Typography variant="h6">
                Zulassungsbescheinigung Teil 1 hochladen
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.content}>
                Wir brauchen von Ihnen nur Ihre Zulassungsbescheinigung Teil 1.
                Laden Sie diese im Login-Bereich hoch. Mehr brauchen wir von
                Ihnen nicht!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          className={classes.divider}
        />
        <Typography variant="h4" align="center">
          Ab hier übernehmen wir
        </Typography>
        <Grid container className={classes.stepsContainer}>
          <Grid item xs={1} className={classes.stepGrid}>
            <Typography variant="h3" className={classes.number}>
              3.
            </Typography>
          </Grid>
          <Grid item xs={11} className={classes.cardGridFirma}>
            <Grid item xs={12} className={classes.gridSubtitle}>
              <IconButton>
                <AssignmentTurnedInIcon className={classes.icon} />
              </IconButton>

              <Typography variant="h6">Prüfung der Dokumente</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.content}>
                Wir prüfen Ihre Zulassungsbescheinigung auf Vollständigkeit der
                Angaben und reichen diese bei der zuständigen Behörde ein.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.stepsContainer}>
          <Grid item xs={1} className={classes.stepGrid}>
            <Typography variant="h3" className={classes.number}>
              4.
            </Typography>
          </Grid>
          <Grid item xs={11} className={classes.cardGridFirma}>
            <Grid item xs={12} className={classes.gridSubtitle}>
              <IconButton>
                <EuroIcon className={classes.icon} />
              </IconButton>

              <Typography variant="h6">Auszahlung</Typography>
            </Grid>

            <Grid item xs={10}>
              <Typography variant="body1" className={classes.content}>
                Nach erfolgreicher Verifizierung können Sie sich über Ihre
                Zusatzerlöse in Höhe von <b>{m1Price} €</b> (PKW M1) für das
                Jahr <b>2025</b> freuen!
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.stepsContainer}>
          <Grid item xs={1} className={classes.stepGrid}>
            <Typography variant="h3" className={classes.number}>
              5.
            </Typography>
          </Grid>
          <Grid item xs={11} className={classes.cardGridFirma}>
            <Grid item xs={12} className={classes.gridSubtitle}>
              <IconButton>
                <ExtensionIcon className={classes.icon} />
              </IconButton>

              <Typography variant="h6">
                Bündelung und Verkauf der Quoten
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body1" className={classes.content}>
                Wir bündeln die gesammelten Quoten zu einem Paket, das dann
                weiterverkauft werden kann. Dieser Prozess geschieht unabhängig
                von Ihnen. Sie müssen sich um nichts kümmern!
              </Typography>
            </Grid>
            <div id="beschreibung"></div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Ablauf;
