import React from "react";
import upload from "../../../media/icons/uploadIcon.svg";
import verified from "../../../media/icons/verifiedIcon.svg";
import submitted from "../../../media/icons/submittedIcon.svg";
import approved from "../../../media/icons/approvedIcon.svg";
import dollar from "../../../media/icons/dollarIcon.svg";
export const statuses = [
  {
    value: "uploaded",
    label: "Hochgeladen",
    icon: upload,
  },
  {
    value: "verified",
    label: "Verifiziert",
    icon: submitted,
  },
  {
    value: "submitted",
    label: "Eingereicht",
    icon: verified,
  },
  {
    value: "approved",
    label: "Genehmigt",
    icon: approved,
  },
  {
    value: "paid",
    label: "Geld ausgezahlt",
    icon: dollar,
  },
];

const QuoteStatus = ({ quote, year }) => {
  const statudIdx = statuses.findIndex(({ value }) => value === quote.status);

  return (
    quote.year == year && (
      <div className="w-full border rounded-md shadow-md my-4  p-4 grid grid-cols-1 gap-8">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="flex justify-center items-center gap-4">
            <div className="border border-lightGreen rounded-full">
              <h1 className=" text-lightGreen text-[1rem] leading-[1.4rem] font-[400] py-2 px-4">
                {quote.number_plate}
              </h1>
            </div>
            {quote.vehicle_type && (
              <div className="border border-lightGreen rounded-full">
                <h1 className="text-lightGreen uppercase text-[1rem] leading-[1.4rem] font-[400] py-2 px-4">
                  {quote.vehicle_type}
                </h1>
              </div>
            )}
            {quote.price && (
              <div className="border border-lightGreen rounded-full">
                <h1 className="text-lightGreen text-[1rem] leading-[1.4rem] font-[400] py-2 px-4">
                  {quote.price}
                  {"€"}
                </h1>
              </div>
            )}
          </div>
          <div className="mt-4 sm:mt-0">
            <div className="flex justify-center items-center">
              <div className="flex justify-center items-center mx-4">
                {["approved", "submitted", "verified", "uploaded"].includes(
                  quote.status
                ) ? (
                  <>
                    <p className="mx-2 text-lightGreen">In Bearbeitung</p>
                    <div
                      className={
                        "border-2 w-3 h-3 bg-[#FFCC00] rounded-full ring-2 ring-[#FFCC00] ring-offset-2 hover:cursor-pointer "
                      }
                    />
                  </>
                ) : quote.status === "paid" ? (
                  <>
                    <p className="mx-2 text-lightGreen">
                      Prozess abgeschlossen
                    </p>
                    <div
                      className={
                        "border-2 w-3 h-3 bg-green-400 rounded-full ring-2 ring-green-400 ring-offset-2 hover:cursor-pointer "
                      }
                    />
                  </>
                ) : (
                  <>
                    <p className="mx-2 text-lightGreen">
                      Prozess vorzeitig beendet
                    </p>
                    <div
                      className={
                        "border-2 w-3 h-3 bg-[#FF3B30] rounded-full ring-2 ring-[#FF3B30] ring-offset-2 hover:cursor-pointer "
                      }
                    />
                  </>
                )}
              </div>
              {/* <div className="flex justify-center items-center mx-4 hover:cursor-pointer">
              <p className="mx-2 text-lightGreen">Bearbeiten</p>
              <img
                src={editIcon}
                alt="edit icon"
                className="w-4 h-4 text-lightGreen"
              />
            </div> */}
            </div>
          </div>
        </div>
        <div className="hidden sm:block">
          <div className="grid grid-cols-5 mt-8 mb-12  sm:ml-8 lg:ml-12">
            {statuses.map(({ icon, label, value }, idx) => (
              <div key={value} className="grid grid-cols-1 gap-8">
                <div
                  className={
                    idx !== statuses.length - 1
                      ? "relative border-t-4 border-[#CCCCCC]"
                      : "relative"
                  }
                >
                  <div
                    className={
                      statudIdx < idx
                        ? "bg-[#CCCCCC] absolute -top-6 w-12 h-12  text-white rounded-full flex justify-center items-center ring-8 ring-white"
                        : "bg-lightGreen absolute -top-6 w-12 h-12  text-white rounded-full flex justify-center items-center ring-8 ring-white"
                    }
                  >
                    <img src={icon} alt="" className="w-[1.5rem] h-[1.5rem]" />
                  </div>
                </div>
                <p className="break-words">{label}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="flow-root sm:hidden">
          <ul role="list" className="-mb-8">
            {statuses.map(({ icon, label, value }, idx) => (
              <li key={value}>
                <div className="relative pb-8">
                  {idx !== statuses.length - 1 && (
                    <span
                      className="absolute top-4 left-6 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  )}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={
                          statudIdx < idx
                            ? "bg-[#CCCCCC] w-12 h-12 rounded-full flex items-center justify-center ring-8 ring-white"
                            : "bg-lightGreen w-12 h-12 rounded-full flex items-center justify-center ring-8 ring-white"
                        }
                      >
                        <img
                          src={icon}
                          alt=""
                          className="w-[1.5rem] h-[1.5rem]"
                        />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500">{label} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  );
};
export default QuoteStatus;
