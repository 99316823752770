import React from "react";

const Head = ({ handleClick, current }) => {
  const currentYear = new Date().getFullYear();
  const years = [currentYear - 2, currentYear - 1, currentYear];

  return (
    <div className="w-full h-12 bg-lightGreen rounded-md grid grid-cols-2">
      <div className="h-full grid grid-cols-3">
        {years.map((year) => (
          <div
            key={year}
            className={`flex justify-center items-center border-b-4 rounded hover:cursor-pointer transition-all ease-in duration-100 ${
              current === year ? "border-red-500" : "border-lightGreen"
            }`}
            onClick={() => handleClick(year)}
          >
            <span className="text-white text-[1rem] font-[400] leading-[1.4rem]">
              {year}
            </span>
          </div>
        ))}
      </div>
      {/* Additional features can be added here */}
    </div>
  );
};

export default Head;
