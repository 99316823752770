import React, { useState, useEffect } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import MobileMenu from "./MobileMenu";
import MenuIcon from "@material-ui/icons/Menu";
import Link from "../Link";
import { connect } from "react-redux";
import { isLoggedIn } from "../../api";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0.5rem",
    transition: "all .2s ease-in",
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,

    zIndex: "5",
  },
  btn: {
    color: theme.palette.primary.light,
    fontSize: "clamp(0.5rem, 2vw, 0.9rem)",
    // fontWeight: "bold",
    "&:hover": {
      color: "#9399a2",
      background: "none",
    },
  },
  headerOptions: {
    display: "flex",
    // flex: 1,
    justifyContent: "start",
  },
  btnReg: {
    display: "flex",
    justifyContent: "flex-end",
  },
  search: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  iconBox: {
    flexgrow: 1,
    justifyContent: "end",
  },
  menuIcon: {
    color: theme.palette.primary.light,
    fontSize: "2.5rem",
  },
  toolbar: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const Navbar = ({ user }) => {
  const classes = useStyles();

  const antiMobile = useMediaQuery("@media (min-width:1260px)");
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    if (antiMobile) {
      setMenu(false);
    }
  }, [antiMobile]);

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.root} flexgrow={1}>
          <Toolbar variant="regular" className={classes.toolbar}>
            <div className={classes.headerOptions}>
              <Link href="/">
                <Logo />
              </Link>
            </div>
            {antiMobile ? (
              <>
                <div className={classes.search}>
                  {navigation.map(({ name, href, external }, index) => (
                    <Button
                      key={index}
                      component={Link}
                      href={href}
                      external={external}
                      className={classes.btn}
                    >
                      {name}
                    </Button>
                  ))}
                </div>
                <div className={classes.btnReg}>
                  {(isLoggedIn()
                    ? authenticatedButtons
                    : unauthenticatedButtons
                  ).map((button, index) => (
                    <Button
                      key={index}
                      className={classes.btn}
                      component={Link}
                      href={button.href}
                    >
                      {button.name}
                    </Button>
                  ))}
                </div>
              </>
            ) : (
              <IconButton
                className={classes.iconBox}
                edge="start"
                aria-label="menu"
                onClick={() => setMenu(!menu)}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {!antiMobile && (
          <MobileMenu
            state={menu}
            setState={setMenu}
            navigation={navigation}
            authenticatedButtons={authenticatedButtons}
            unauthenticatedButtons={unauthenticatedButtons}
          />
        )}
      </div>
    </>
  );
};

export default connect((state) => ({
  user: state.user,
}))(Navbar);

const navigation = [
  {
    name: "THG-Quotenservice",
    href: "/#vorteile",
    external: false,
  },
  {
    name: "Dienstleistungen",
    href: "/#dienstleistungen",
    external: false,
  },
  {
    name: "so funktioniert's",
    href: "/#ablauf",
    external: false,
  },
  {
    name: "Erfahren sie mehr",
    href: "/#beschreibung",
    external: false,
  },
  // {
  //   name: "Wallbox-Aktion",
  //   href: "/wallbox",
  //   external: false,
  // },
  {
    name: "FAQ",
    href: "/faq",
    external: false,
  },
  {
    name: "Kontakt",
    href: "/contact",
    external: false,
  },
];

const unauthenticatedButtons = [
  {
    name: "Einloggen",
    href: "/login",
    external: false,
  },
  {
    name: "Registrieren",
    href: "/register",
    external: false,
  },
];

const authenticatedButtons = [
  {
    name: "Mein Bereich",
    href: "/authenticated/",
    external: false,
  },
  {
    name: "Logout",
    href: "/authenticated/logout",
    external: false,
  },
];
